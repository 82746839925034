import { Worklist } from '@/models/worklist.model';
import { GenericService } from '@/services/api/generic.service';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '@/services/api/client/apiClient';
import { TagFilter } from '@/models';

export default class WorklistService extends GenericService<Worklist> {
  constructor() {
    super();
    this.endpoint = 'v1/worklists';
  }

  async getWorklistTags(
    pathway: string,
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<TagFilter>> {
    return (await apiClient.get(`${this.endpoint}/${pathway}/tags`, params)).data ?? [];
  }
}
